<template>

  <div class="equation">

    <div class="first-screen pr">
      <video
          ref="YL"
          @playing.once="playing = true"
          class="abs"
          src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/大学生方程式视频预览.mp4"
          preload
          autoplay
          loop
          muted
      >
      </video>

      <div class="content-wrap pr tc" v-show="!isPlay">
        <div>
          <div class="titles">
            <div class="slogan">{{ bannerData.videoWordOne }}</div>
            <div class="subtitle">{{ bannerData.videoWordTwo }}</div>
          </div>
          <div class="play" @click="playVideo">
            点击查看完整视频
            <img src="../../assets/img/play-icon.png" alt="" style="width: 26.5px;margin-top: -3px">
          </div>
        </div>
      </div>
    </div>

    <ul class="nio-car">
      <li class="nio-car-list nio-car-list-left">
        <img src="../../assets/img/fcs-1.png" alt="">
        <p class="nio-car-title">“蔚来杯”是什么？</p>
        <p class="nio-car-subtitle">
          "蔚来杯"中国大学生方程式系列赛事是一项纯公益项目，由中国汽车工程学会主办，面向在校大学生，参赛团队每年独立完成一台方程式赛车的设计、制造和调试工作，赛事为青年学生提供了一个锻炼自己、展示才华的平台。
        </p>
      </li>
      <li class="nio-car-list nio-car-list-left">
        <img src="../../assets/img/fcs-2.png" alt="">
        <p class="nio-car-title">为什么开设“蔚来杯”？</p>
        <p class="nio-car-subtitle">
          在谈到中国汽车研发与世界先进水平的差距时，蔚来CEO李斌表示中国汽车人才主要在动手能力和动手机会方面存在不足。
          基于这样的考量，斌哥参与创办了2010年第一届中国大学生方程式汽车大赛。2015年，“蔚来杯”中国大学生电动方程式大赛独立成赛，旨在围绕电动技术培养大学生实践动手能力，为大学生提供一个融合产业、教育和研究的平台。
        </p>
      </li>
      <li class="nio-car-list">
        <img src="../../assets/img/fcs-3.png" alt="">
        <p class="nio-car-title">支持中国汽车的未来</p>
        <p class="nio-car-subtitle">
          2010年至今，大学生方程式系列赛事已累计为中国汽车行业输送四万多名人才，学生独立设计制造赛车1600余台，出国参赛58队次，获得国际奖项
          35 项，撰写技术论文上万篇。近百名优秀队员加入蔚来，在各个岗位发光发热。
        </p>
      </li>
    </ul>

    <div style="background: #F7F8FA;display: flex;justify-content: center;align-items: center;padding-bottom: 80px">
      <div class="diary-wrap">
        <div class="title">大学生方程式大事记</div>
        <el-carousel indicator-position="none" :autoplay="false" ref="nop">
          <el-carousel-item v-for="item in diaryList" :key="item.text">
            <div class="diary-list">
              <img :src="item.img" alt="">
              <p class="diary-text">{{ item.text }}</p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <ul class="indicator-wrap">
      <li v-for="(item,index) in indicatorList"
          :key="item"
          class="indicator-wrap-list"
          :class="active===index?'active':''"
          @click="changeBanner(index)">
        <span>{{ item }}</span>
      </li>
    </ul>


    <!--    <div style="display: flex;justify-content: center;align-items: center;">-->
    <!--      <div class="diary-wrap-1" style="padding-top: 0">-->
    <!--        <div class="title" style="margin-bottom: 70px">Hello NIOer - 来自大学生方程式的师兄师姐</div>-->
    <!--        <el-carousel indicator-position="none" :autoplay="false" ref="fcs">-->
    <!--          <el-carousel-item v-for="item in FCSList" :key="item.text">-->
    <!--            <div class="diary-list-1">-->
    <!--              <img :src="item.img" alt="">-->
    <!--              <div class="right">-->
    <!--                <p class="name">{{ item.name }}</p>-->
    <!--                <div>-->
    <!--                  <div class="line"></div>-->
    <!--                  <p class="grade1">{{ item.text1 }}</p>-->
    <!--                  <p class="grade1" style="margin-bottom: 55px">{{ item.text2 }}</p>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </el-carousel-item>-->
    <!--        </el-carousel>-->
    <!--        <div class="arrow">-->
    <!--          <div class="left_yuan" v-show="curIndex===0">-->
    <!--            <img src="../../assets/img/left-arrow.png" alt="" style="width: 54px">-->
    <!--          </div>-->
    <!--          <div class="left_yuan" @click="cardPunt()" v-show="curIndex!==0">-->
    <!--            <img src="../../assets/img/right-arrow.png" alt="" style="width: 54px;transform: rotate(180deg)">-->
    <!--          </div>-->
    <!--          <div class="right_yuan" v-show="curIndex===7">-->
    <!--            <img src="../../assets/img/left-arrow.png" alt="" style="width: 54px;transform: rotate(180deg)">-->
    <!--          </div>-->
    <!--          <div class="right_yuan" @click="cardNext()" v-show="curIndex!==7">-->
    <!--            <img src="../../assets/img/right-arrow.png" alt="" style="width: 54px">-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="fcs-wrap">
      <div class="title">Hello NIOer - 来自大学生方程式的师兄师姐</div>
      <ul class="fcs-list-wrap">
        <li class="fcs-list" v-for="(item, index) in FCSList2" :key="index">
          <img class="fcs-img" :src="item.img" alt="">
          <p class="fcs-name">{{ item.name }}</p>
          <p class="fcs-desc1">{{ item.text1 }}</p>
          <p class="fcs-desc2">{{ item.text2 }}</p>
        </li>
      </ul>
    </div>

    <div class="equation-zongjie">
      <div class="title" style="margin-bottom: 32px">加入蔚来，延续对速度与梦想的热爱</div>
      蔚来欢迎所有参与过大学生方程式相关赛事的同学加入<br>
      整车研发、电驱研发、智能驾驶、蔚来能源、试验中心、智能制造、区域公司等方向，超多校招及实习岗位面向车队选手们开放投递。<br>
      我们会开设车队专项校招/实习项目，同时也为所有参与过大学生方程式相关赛事的同学添加赛事专属标记，<br>
      在与方程式高相关度的岗位招聘中，向业务/HR面试官特别推荐。<br>
      同时欢迎大家联系曾经在车队中共事，现在已经加入蔚来的师兄师姐，<br>
      通过内推渠道投递，可以更精确地定位到你心仪的岗位！
    </div>

    <!-- 小视频弹框  -->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="加入蔚来，延续对速度与梦想的热爱"
        :visible.sync="dialogTableVisible"
        @close="closeDialog"
    >
      <video
          ref="videos"
          @playing.once="playing = true"
          @ended="onPlayerEnded($event)"
          src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/大学生方程式视频.mp4"
          controls
          autoplay
      ></video>
    </el-dialog>

  </div>

</template>

<script>

export default {
  props: {
    equation: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  name: "equation",
  components: {},
  data() {
    return {
      bannerData: {
        id: "1",
        videoName: "首页视频",
        videoUrl:
            "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_home_video1.mp4",
        videoWordOne: "加入蔚来，延续对速度与梦想的热爱",
        videoWordTwo: "“蔚来杯”大学生方程式系列赛事助力每一份年少时的梦想",
        createTime: "2022-05-10 14:13:02",
        updateTime: "2022-05-10 16:46:28",
        deleted: 0,
        linkUrl:
            "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=7257447706321455397&type=&job_hot_flag=&current=1&limit=10&functionCategory=&tag=&spread=7Q6WWWR",
        empty: false,
      },
      loading: true,
      playing: false,
      isPlay: false,
      diaryList: [
        {
          text: '蔚来创始人李斌联合中国汽车工程学会，深度参与创办了第一届中国大学生方程式汽车大赛',
          img: require('@/assets/img/2010.png')
        },
        // {
        //   text: '第一届中国大学生电动方程式大赛(FSEC)成立',
        //   img: require('@/assets/img/fcs-5.jpg'),
        // },
        {
          text: '“蔚来杯”电动方程式独立成赛，并举办第一届总决赛',
          img: require('@/assets/img/2015.png'),
        },
        {
          text: '广西科技大学鹿山学院、湖北汽车工业学院代表中国参加德国赛',
          img: require('@/assets/img/2016.png'),
        },
        {
          text: '湖北汽车工业学院参加德国赛，霍根海姆赛道上的中国红',
          img: require('@/assets/img/2018.png'),
        },
        {
          text: '蔚来冠名大学生无人驾驶方程式大赛 (FSAC)',
          img: require('@/assets/img/2021.png')
        },
        {
          text: '蔚来正式成为德国大学生方程式赛事的合作伙伴，为全球汽车工业新生力量的梦想持续加电',
          img: require('@/assets/img/2022.jpg')
        },
      ],
      active: 0,
      indicatorList: ['2010', '2015', '2016', '2018', '2021', '2022'],
      curIndex: 0,
      dialogTableVisible: false,
      videoUrl: null,
      title: null,
      disabledPlay: true,
      FCSList2: [
        {
          name: '李天舒',
          text1: '北航AERO大学方程式赛车队首届队长',
          text2: '蔚来产品体验负责人',
          img: require('@assets/img/1.png')
        },
        {
          name: 'Max',
          text1: 'SJTU Racing Team上海交通大学赛车队',
          text2: '蔚来智能驾驶仿真主任工程师',
          img: require('@assets/img/2.png')
        },
        {
          name: 'Yanran',
          text1: 'ZJU Horizon 浙江大学启真方程式赛车队',
          text2: '蔚来整车应用软件资深项目经理',
          img: require('@assets/img/3.png')
        },
        {
          name: '浩子',
          text1: '广东工业大学FSAE车队',
          text2: '蔚来车辆动力性能开发主任工程师',
          img: require('@assets/img/4.png')
        },
        {
          name: '明昊',
          text1: '东风HUAT车队',
          text2: '蔚来上海大宁国际商业广场门店总经理',
          img: require('@assets/img/5.jpg')
        },
        {
          name: 'Jerry Wang',
          text1: 'SJTU Racing Team上海交通大学赛车队',
          text2: '蔚来换电体验团队负责人',
          img: require('@assets/img/6.png')
        },
        {
          name: '鹏程',
          text1: '哈尔滨工业大学(威海)HRT赛车队',
          text2: '蔚来智能驾驶系统高级工程师',
          img: require('@assets/img/7.png')
        },
        {
          name: 'Justin Wang',
          text1: '合肥工业大学方程式赛车队',
          text2: '蔚来质量规划主任工程师',
          img: require('@assets/img/8.jpg')
        },
      ]
    }
  },
  methods: {
    closeDialog() {
      // 关闭弹框
      this.dialogTableVisible = false;
      this.videoUrl = null;
      // this.$refs.videos.controls = false
      this.$refs.videos.pause();
      this.$refs.YL.play();
    },
    onPlayerEnded() {
      this.disabledPlay = true;
      this.$refs.videos.controls = true;
      this.$refs.videos.pause();
      this.$refs.videos.load();
    },
    playVideo() {
      // this.isPlay = true;
      // this.$refs.video.play();
      this.dialogTableVisible = true;
      this.$refs.YL.pause();
    },
    changeBanner(active) {
      this.active = active;
      this.$refs.nop.setActiveItem(active);
    },
    cardPunt() {
      this.curIndex = this.curIndex - 1;
      this.$refs.fcs.prev()
    },
    cardNext() {
      this.curIndex = this.curIndex + 1;
      this.$refs.fcs.next()
    },
  },
  mounted() {
    // this.$refs.video.addEventListener('ended', () => {
    //   this.isPlay = false;
    // })
  }
}
</script>

<style scoped>

.fcs-wrap {
  width: 1200px;
  margin: 0 auto;
  /*padding-top: 90px;*/
  padding-bottom: 110px;
}

.fcs-list-wrap{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.fcs-list {
  width: 276px;
  color: #000000;
  margin-bottom: 60px;
}

.fcs-img {
  width: 276px;
  height: 200px;
  margin-bottom: 16px;
}

.fcs-name {
  font-size: 22px;
  line-height: 34.89px;
  margin-bottom: 6px;
}

.fcs-desc1, .fcs-desc2 {
  font-size: 14px;
  line-height: 22.2px;
  margin-bottom: 2px;
}
.fcs-desc2{
  margin-bottom: 0px;
}
.equation-zongjie {
  text-align: center;
  background: #F7F8FA;
  padding-top: 80px;
  padding-bottom: 110px;
  font-size: 16px;
  line-height: 49.3px;
  color: #040B29;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 58px;
  margin-top: 55px;
}

.arrow .left_yuan {
  margin-right: 74.5px;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 42px;
  padding-top: 50px;
  box-shadow: 0 0 15px 0 rgba(24, 26, 32, 0.01);
}

.name {
  font-size: 32px;
  line-height: 50.75px;
  color: #000000;
}

.line {
  border-bottom: 1px solid #000000;
  width: 120px;
  margin-bottom: 22px;
}

.grade1 {
  font-size: 16px;
  line-height: 25.38px;
}

.indicator-wrap {
  display: flex;
  padding-left: 80px;
  padding-right: 80px;
  position: relative;
  top: -120px;
  height: 80px;
}

.indicator-wrap-list {
  text-align: center;
  font-size: 16px;
  flex: 1;
  border-top: 2px solid #D9D9D9;
  padding-top: 12px;
  transition: all 0.2s ease;
  color: #000000;
}

.indicator-wrap-list span {
  cursor: pointer;
}

.indicator-wrap-list.active {
  border-top: 2px solid #00B3BE;
  font-size: 32px;
}

/deep/ .el-carousel__container {
  height: 480px;
}

/deep/ .el-carousel__arrow {
  display: none;
}

.diary-active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.diary-list {
  width: 100%;
  height: 566px;
}

.diary-list-1 {
  display: flex;
  box-shadow: 0 0 15px 0 rgba(24, 26, 32, 0.01);
}

.diary-list-1 img {
  width: 759px;
  height: 100%;
}

.diary-text {
  font-size: 20px;
  line-height: 31.72px;
  margin-top: 18px;
  color: #000000;
}

.diary-list img {
  width: 100%;
  height: 366px;
}


.equation {
  margin-top: -64px;
}

.nio-car {
  width: 1200px;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  color: #000000;
}

.nio-car-list {
  flex: 1;
}

.nio-car-list {
  margin-right: 32px;
}

.nio-car-list img {
  width: 100%;
  height: 228px;
}

.nio-car-title {
  font-size: 22px;
  line-height: 34.89px;
  margin-bottom: 6px;
  margin-top: 26px;
}

.nio-car-subtitle {
  font-size: 14px;
  line-height: 22.2px;
}

.diary-wrap {
  width: 800px;
  padding-top: 80px;
  padding-bottom: 80px;
}

.diary-wrap-1 {
  width: 1200px;
  padding-top: 80px;
}

.title {
  text-align: center;
  font-size: 36px;
  line-height: 57.1px;
  margin-bottom: 80px;
  color: #040B29;
}
</style>

<style scoped lang="less">
.play {
  color: #FFFFFF;
  font-size: 18px;
  position: absolute;
  bottom: 44px;
  left: 43.5%;
  cursor: pointer;
}

.scroll-anchor {
  bottom: -1px;
}

@keyframes jump {
  0% {
    padding-top: 2px;
  }
  50% {
    padding-top: 9px;
  }
  100% {
    padding-top: 2px;
  }
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  left: 0;
  top: 0;
}

.first-screen {
  // width: 100vw;
  height: 100vh;
  //height: 900px;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    left: 0;
    top: 0;
  }

  .show-bg {
    background-color: rgba(156, 198, 207, 0.75) !important;
  }

  .content-wrap {
    padding-top: 200px;
    width: 100%;
    height: 100%;
    z-index: 1;
    // background: rgba(19, 60, 154, 0.2);
    .titles {
      height: 125px;
    }

    .slogan {
      font-size: 56px;
      color: #fff;
      line-height: 82px;
      letter-spacing: 7px;
      font-weight: 100;
    }

    .subtitle {
      margin-top: 8px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
      letter-spacing: 2px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        width: 80%;
      }
    }

    .apply-btns {
      display: flex;
      justify-content: center;

      margin: 72px auto 0;
      width: 476px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 222px;
        height: 48px;
        // line-height: 48px;
        // text-align: center;
        border-radius: 2px;
        background-color: transparent;
        transition: background-color 0.3s;

        &:first-child {
          background-color: #fff;
          color: #00b3be;

          &:hover {
            background-color: #00b3be;
            color: #fff;
          }
        }

        &:last-child {
          line-height: 46px;
          background-color: rgba(255, 255, 255, 0.1);
          color: #ffffff;

          &:hover {
            border: 1px solid #00b3be;
            background-color: #00b3be;
            color: #fff;
          }
        }
      }
    }
  }

  .tap-wrap {
    bottom: 15%;
    z-index: 2;

    .anchor-wrap {
      display: inline-block;
      width: 30px;
      height: 30px;
      // border: 1px solid #00b3be;
      // border-radius: 50%;
      animation: jump 1.5s linear infinite;
    }

    a {
      display: inline-block;
      width: 24px;
      height: 29px;
      background: url("~@/assets/img/slide-down.png") center no-repeat;
      background-size: contain;
    }
  }
}
</style>